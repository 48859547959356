import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Banner from '../components/banner';
import PoliticsManual from '../components/politics-manual';
import PoliticsAccordion from '../components/politics-accordion';

export interface PoliticsProps {}

const Politics: React.FC<PoliticsProps> = () => (
  <div className="screen__container">
    <Layout>
      <SEO title="Politicas y privacidad" />
      <Banner
        title="Nuestro lema es la protección"
        subtitle="Por esto tus datos, también están seguros con nosotros."
        image="/images/Proyectat-07.png"
        mobileBackgroundColor="#D6E848"
        hasButton={false}
        dark={true}
      ></Banner>
      <PoliticsManual />
      <PoliticsAccordion />
    </Layout>
  </div>
);

export default Politics;
