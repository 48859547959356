import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { AccordionInterface } from '../models/Accordion';
import '../scss/politics-accordion.scss';
import { Link } from 'gatsby';

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const ACCORDIONS: AccordionInterface[] = [
  {
    title: '1. Responsable del tratamiento de los datos personales',
    description:
      'Proyectat es la primera agencia máster de seguros en el país que desde su creación, no ha parado de crecer y revolucionar la forma de disfrutar de los seguros. <br /><br /> Somos una familia de consultores financieros de alto desempeño que sumamos cientos de años de experiencia en proteger la tranquilidad de nuestros clientes. Ofrecemos productos innovadores que mejoran la calidad de vida financiera tanto de nuestros aliados como de nuestros asegurados.',
  },
  {
    title: '2. Definiciones',
    description:
      'Proyectat ofrece a sus clientes asesoría financiera de alto nivel, Somos los únicos en el país que hacen parte de MDRT, Million Dollar Round Table que reúne a los mejores profesionales de servicios financieros y de seguros de vida más importantes del mundo. Esta membresía es reconocida internacionalmente como el estándar de excelencia en el negocio de seguros de vida. <br /><br /> Ofrecemos el primer Primer seguro de vida con indexación bursátil del país, lo que le permite a nuestros asegurados estar tranquilos en todos los momentos de su vida mientras acumulan capital para cumplir sus objetivos financieros. Además acompañamos a importantes compañías del país a desarrollar efectivamente sus canales de vida individual.',
  },
  {
    title: '3. Ámbito de aplicación',
    description:
      'En Proyectat no sólo estás comprando un seguro, desde que haces parte de nuestra familia, cuentas con el acompañamiento de un consultor financiero especializado experto en convertir tus planes financieros en realidad.',
  },
  {
    title: '4. Legitimación y derechos del titular',
    description:
      'Proyectat reúne a los mejores profesionales financieros del sector y los productos más innovadores del mercado. Nuestro objetivo es que estés tranquilo y seguro. Somos expertos en la creación de canales de distribución en vida individual y WSM en Colombia y Latam. Soportados con desarrollo comercial que nos permite manejar la línea completa de creación del equipo para lograr un alto desempeño, desarrollo permanente del talento, crecimiento sostenido y rentabilidad.',
  },
  {
    title: '5. Tratamiento de los datos',
    description:
      'Cuando adquieres un seguro de vida con indexación bursátil, significa que una parte de la prima que pagas es destinada al rubro de protección que incluyas en tu póliza, la otra parte, se destina al rubro de acumulación, que a partir de determinado tiempo, con base en ese capital, puedes solicitar un valor de rescate, bonos de fidelidad y rendimientos indexados al SP 500, uno de los índices bursátiles de mejor rendimiento.',
  },
  {
    title: '6. Garantías del derecho de acceso',
    description:
      'El acrónimo SP500 es un índice bursátil donde se recopilan las 500 empresas más grandes de Estados Unidos. <br /><br /> Este es uno de los índices de renta variable más seguido y es considerado como referente para la economía de los EE.UU. Además es uno de los índices más utilizados por los inversores institucionales, ya que incorpora una de las carteras más grandes de empresas.',
  },
];

export interface AccordionProps {}

const PoliticsAccordion: React.FC<AccordionProps> = props => {
  const [expanded, setExpanded] = React.useState<string | false>();

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    newExpanded: boolean
  ) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className="PoliticsAccordion__container">
      <div className="container">
        <Accordion
          square
          expanded={expanded === `panel0`}
          onChange={handleChange(`panel0`)}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <h3 className="PoliticsAccordion__title">
              1. IDENTIFICACIÓN DEL RESPONSABLE DEL TRATAMIENTO DE LOS DATOS
              PERSONALES
            </h3>
          </AccordionSummary>
          <AccordionDetails>
            <div className="PoliticsAccordion__item-container">
              <p className="PoliticsAccordion__item-description">
                <strong>PROYECTA-T LTDA</strong>, es una sociedad comercial
                legalmente constituida, cuyo objeto social las actividades
                propias de una Agencia de Seguros con funciones de corredor de
                seguros, identificada la matricula mercantil No. 21-593776-03 y
                con el NIT. 901096692-7.
              </p>
              <h4 className="PoliticsAccordion__item-subtitle">PROPÓSITO</h4>
              <p className="PoliticsAccordion__item-description">
                <strong>PROYECTA-T LTDA</strong>, dando cumplimiento a lo
                establecido en la Ley Estatutaria 1581 de 2012, el Decreto
                Reglamentario 1377 de 2013, la Circular 02 de 2015 de la
                Superintendencia de Industria y Comercio y demás disposiciones
                vigentes sobre la materia adopta la presente política para el
                tratamiento de datos personales, con el fin de definir los
                alcances y las políticas internas de{' '}
                <strong>PROYECTA-T LTDA</strong> como responsable en el manejo
                de los datos personales, la cual será informada a todos los
                titulares de los datos recolectados o que en el futuro se
                obtengan en ejercicio de las actividades empresariales,
                comerciales y laborales de la empresa.
              </p>
              <h4 className="PoliticsAccordion__item-subtitle">ALCANCE</h4>
              <p className="PoliticsAccordion__item-description">
                El presente manual, abarca lo correspondiente a las bases de
                datos desde la celebración y ejecución de los contratos de
                seguro y cualquier otro que se celebren por parte de la empresa,
                la recepción y manejo de las consultas, la atención de
                peticiones, quejas o reclamos entre otros.
              </p>
              <div className="table__container">
                <table>
                  <thead>
                    <th>Nombre</th>
                    <th>Cargo</th>
                    <th>Fecha</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Responsable: Luisa Jaramillo Londoño</td>
                      <td>Representante Legal</td>
                      <td>09-08-2018</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <h4 className="PoliticsAccordion__item-subtitle">
                MARCO NORMATIVO
              </h4>
              <div className="table__container">
                <table>
                  <thead>
                    <th>Norma</th>
                    <th>No.</th>
                    <th>Año</th>
                    <th>Entidad o Corporación</th>
                    <th>Contenido</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Sentencia</td>
                      <td>C-1011</td>
                      <td>2008</td>
                      <td>Corte Constitucional</td>
                      <td>
                        Ley estatutaria de habeas data y manejo de información
                        contenida en bases de datos personales
                      </td>
                    </tr>
                    <tr>
                      <td>Decreto</td>
                      <td>1727</td>
                      <td>2009</td>
                      <td>Presidencia de la República</td>
                      <td>
                        Determina la forma en la cual los operadores de los
                        bancos de datos de información financiera, crediticia,
                        comercial, de servicios y la proveniente de terceros
                        países, deben presentar la información de los titulares
                        de la información
                      </td>
                    </tr>
                    <tr>
                      <td>Decreto</td>
                      <td>2952</td>
                      <td>2010</td>
                      <td>Presidencia de la República</td>
                      <td>
                        Se reglamentan los artículos 12 y 13 de la Ley 1266 de
                        2008 (Habeas Data)
                      </td>
                    </tr>
                    <tr>
                      <td>Sentencia</td>
                      <td>C-748</td>
                      <td>2011</td>
                      <td>Corte Constitucional</td>
                      <td>
                        Ley estatutaria de habeas data y manejo de información
                        contenida en bases de datos personales
                      </td>
                    </tr>
                    <tr>
                      <td>Ley</td>
                      <td>1581</td>
                      <td>2012</td>
                      <td>Congreso de la República</td>
                      <td>
                        Desarrollo Constitucional de conocer, actualizar y
                        rectificar las informaciones que se tengan en las bases
                        de datos o archivos, en consonancia con el Art. 15 y 20
                        de la Constitución Política
                      </td>
                    </tr>
                    <tr>
                      <td>Decreto</td>
                      <td>1377</td>
                      <td>2013</td>
                      <td>Presidencia de la Republica</td>
                      <td>
                        Se reglamenta parcialmente la Ley 1581 de 2012 (régimen
                        General de Protección de Datos)
                      </td>
                    </tr>
                    <tr>
                      <td>Circular</td>
                      <td>2</td>
                      <td>2015</td>
                      <td>Superintendencia de Industria y Comercio</td>
                      <td>
                        Inscribir las bases de datos que tengas establecidas las
                        sociedades mercantiles inscritas en la Cámara de
                        Comercio, en el Registro Nacional de Bases de Datos.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          square
          expanded={expanded === `panel1`}
          onChange={handleChange(`panel1`)}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <h3 className="PoliticsAccordion__title">
              2. DEFINICIONES<sup>1</sup>
            </h3>
          </AccordionSummary>
          <AccordionDetails>
            <div className="PoliticsAccordion__item-container">
              <p className="PoliticsAccordion__item-description">
                Para los efectos de la presente política y en concordancia con
                la normatividad vigente en materia de protección de datos
                personales, se tendrán en cuenta las siguientes definiciones:
              </p>
              <p className="PoliticsAccordion__item-description">
                <strong>Autorización:</strong> Consentimiento previo, expreso e
                informado del Titular para llevar a cabo el Tratamiento de datos
                personales.
              </p>
              <p className="PoliticsAccordion__item-description">
                <strong>Base de Datos:</strong> Conjunto organizado de datos
                personales que sea objeto de Tratamiento
              </p>
              <p className="PoliticsAccordion__item-description">
                <strong>Dato personal:</strong> Cualquier información vinculada
                o que pueda asociarse a una o varias personas naturales
                determinadas o determinables.
              </p>
              <p className="PoliticsAccordion__item-description">
                <strong>Encargado del Tratamiento:</strong> Persona natural o
                jurídica, pública o privada, que por sí misma o en asocio con
                otros, realice el Tratamiento de datos personales por cuenta del
                Responsable del Tratamiento.
              </p>
              <p className="PoliticsAccordion__item-description">
                <strong>Responsable del Tratamiento:</strong> Persona natural o
                jurídica, pública o privada, que por sí misma o en asocio con
                otros, decida sobre la base de datos y/o el Tratamiento de los
                datos.
              </p>
              <p className="PoliticsAccordion__item-description">
                <strong>Titular:</strong> Persona natural cuyos datos personales
                sean objeto de Tratamiento.
              </p>
              <p className="PoliticsAccordion__item-description">
                <strong>Tratamiento:</strong> Cualquier operación o conjunto de
                operaciones sobre datos personales, tales como la recolección,
                almacenamiento, uso, circulación o supresión.
              </p>
              <p className="PoliticsAccordion__item-description">
                <strong>Aviso de privacidad:</strong> Comunicación verbal o
                escrita generada por el Responsable, dirigida al Titular para el
                Tratamiento de sus datos personales, mediante la cual se le
                informa acerca de la existencia de las políticas de Tratamiento
                de información que le serán aplicables, la forma de acceder a
                las mismas y las finalidades del Tratamiento que se pretende dar
                a los datos personales.
              </p>
              <p className="PoliticsAccordion__item-description">
                <strong>Dato público:</strong> Es el dato que no sea
                semiprivado, privado o sensible. Son considerados datos
                públicos, entre otros, los datos relativos al estado civil de
                las personas, a su profesión u oficio ya su calidad de
                comerciante o de servidor público. Por su naturaleza, los datos
                públicos pueden estar contenidos, entre otros, en registros
                públicos, documentos públicos, gacetas y boletines oficiales y
                sentencias judiciales debidamente ejecutoriadas que no estén
                sometidas a reserva.
              </p>
              <p className="PoliticsAccordion__item-description">
                <strong>Datos sensibles:</strong> Se entiende por datos
                sensibles aquellos que afectan la intimidad del Titular o cuyo
                uso indebido puede generar su discriminación, tales como aquello
                que revelen el origen racial o étnico, la orientación política,
                las convicciones religiosas o filosóficas, la pertenencia a
                sindicatos, organizaciones sociales, de derechos humanos o que
                promueva intereses de cualquier partido político o que
                garanticen los derechos y garantías de partidos políticos de
                oposición, así como los datos relativos a la salud, a la vida
                sexual, y los datos biométricos.
              </p>
              <p className="PoliticsAccordion__item-description">
                <strong>Transferencia:</strong> La transferencia de datos tiene
                lugar cuando el Responsable y/o Encargado del Tratamiento de
                datos personales, ubicado en Colombia, envía la información o
                los datos personales a un receptor, que a su vez es Responsable
                del Tratamiento y se encuentra dentro o fuera del país.
              </p>
              <p className="PoliticsAccordion__item-description">
                <strong>Transmisión:</strong> Tratamiento de datos personales
                que implica la comunicación de los mismos dentro o fuera del
                territorio de la República de Colombia cuando tenga por objeto
                la realización de un Tratamiento por el Encargado por cuenta del
                Responsable.
              </p>
              <p className="PoliticsAccordion__item-law">
                <sup>1</sup> Art. 3, Ley 1581 de 2012, disposiciones generales
                para la protección de datos personales en consonancia con el
                Art. 4, Decreto 1377 de 2013, régimen general de protección de
                datos personales.
              </p>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          square
          expanded={expanded === `panel2`}
          onChange={handleChange(`panel2`)}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <h3 className="PoliticsAccordion__title">
              3. AMBITO DE APLICACIÓN<sup>2</sup>
            </h3>
          </AccordionSummary>
          <AccordionDetails>
            <div className="PoliticsAccordion__item-container">
              <p className="PoliticsAccordion__item-description">
                La presente política será aplicable a los datos personales
                registrados en cualquier base de datos de{' '}
                <strong>PROYECTA-T</strong> cuyo titular sea una persona
                natural.
              </p>
              <h4 className="PoliticsAccordion__item-subtitle">
                PRINCIPIOS RECTORES<sup>3</sup>
              </h4>
              <p className="PoliticsAccordion__item-description">
                Según lo establecido en Ley 1581 de 2002, los principios que
                rigen el tratamiento de los datos personales de manera armónica
                e integral son:
              </p>
              <ul>
                <li>
                  <strong>
                    Principio de legalidad en materia de Tratamiento de datos:
                  </strong>{' '}
                  El tratamiento de datos es una actividad reglada, la cual
                  deberá estar sujeta a las disposiciones legales vigentes y
                  aplicables rigen el tema.
                </li>
                <li>
                  <strong>Principio de finalidad:</strong> La actividad del
                  tratamiento de datos personales que realice el titular del
                  tratamiento de los datos o a la cual tuviere acceso,
                  obedecerán a una finalidad legítima en consonancia con la
                  Constitución Política de Colombia, la cual deberá ser
                  informada al respectivo titular de los datos personales.
                </li>
                <li>
                  <strong>Principio de libertad:</strong> El tratamiento de los
                  datos personales sólo puede realizarse con el consentimiento,
                  previo, expreso e informado del Titular. Los datos personales
                  no podrán ser obtenidos o divulgados sin previa autorización,
                  o en ausencia de mandato legal, estatutario, o judicial que
                  releve el consentimiento.
                </li>
                <li>
                  <strong>Principio de veracidad o calidad:</strong> La
                  información sujeta a Tratamiento de datos personales debe ser
                  veraz, completa, exacta, actualizada, comprobable y
                  comprensible. Se prohíbe el Tratamiento de datos parciales,
                  incompletos, fraccionados o que induzcan a error.
                </li>
                <li>
                  <strong>Principio de transparencia:</strong> En el tratamiento
                  garantizará al Titular su derecho de obtener en cualquier
                  momento y sin restricciones, información acerca de la
                  existencia de cualquier tipo de información o dato personal
                  que sea de su interés o titularidad.
                </li>
                <li>
                  <strong>
                    Principio de acceso y circulación restringida:
                  </strong>{' '}
                  El tratamiento de los datos se sujeta a los límites que
                  establecen las normas propias a los mimos (ley y la
                  Constitución). Por lo tanto, el tratamiento sólo podrá hacerse
                  por personas autorizadas por el titular y/o por las personas
                  previstas en la ley.
                </li>
                <li>
                  <strong>Principio de seguridad:</strong> La información sujeta
                  a tratamiento por el responsable del tratamiento de los datos,
                  deberá garantizar la reserva de la información a través de as
                  medidas técnicas, humanas y administrativas que sean
                  necesarias que permitan otorgar seguridad a los registros
                  evitando su adulteración, pérdida, consulta, uso o acceso no
                  autorizado o fraudulento.
                </li>
                <li>
                  <strong>Principio de confidencialidad:</strong> Todas las
                  personas que intervengan en <strong>PROYECTA-T LTDA</strong>,
                  están obligadas a garantizar la reserva de la información,
                  inclusive después de finalizada su relación con alguna de las
                  labores que comprenden el tratamiento, por lo que se
                  comprometen a conservar y mantener de manera estrictamente
                  confidencial y no revelar a terceros, toda la información que
                  llegaren a conocer en la ejecución y ejercicio de sus
                  funciones; salvo cuando se trate de actividades autorizadas
                  expresamente por la ley de protección de datos.
                </li>
              </ul>
              <h4 className="PoliticsAccordion__item-subtitle">
                DERECHOS DEL TITULAR DE LA INFORMACIÓN<sup>4</sup>
              </h4>
              <p className="PoliticsAccordion__item-description">
                De acuerdo con lo contemplado por la normatividad vigente
                aplicable en materia de protección de datos, los siguientes son
                los derechos de los titulares de los datos personales:
              </p>
              <ul>
                <li>
                  Acceder, conocer, actualizar y rectificar sus datos personales
                  frente al responsable del tratamiento de los datos en su
                  condición de responsable del tratamiento. Este derecho se
                  podrá ejercer, entre otros, frente a datos parciales,
                  inexactos, incompletos, fraccionados, que induzcan a error, o
                  aquellos cuyo tratamiento esté expresamente prohibido o no
                  haya sido autorizado.
                </li>
                <li>
                  Solicitar prueba de la autorización otorgada al responsable
                  para el tratamiento de datos, mediante cualquier medio válido,
                  salvo en los casos en que no es necesaria la autorización.
                </li>
                <li>
                  Ser informado por el responsable del tratamiento de los datos,
                  previa solicitud, respecto del uso que le ha dado a sus datos
                  personales.
                </li>
                <li>
                  Presentar ante la Superintendencia de Industria y Comercio, o
                  la entidad que hiciere sus veces, quejas por infracciones a lo
                  dispuesto en la Ley 1581 de 2012 y las demás normas que la
                  modifiquen, adicionen o complementen.
                </li>
                <li>
                  Revocar al responsable del tratamiento de los datos la
                  autorización y/o solicitar la supresión del dato cuando en el
                  Tratamiento no se respeten los principios, derechos y
                  garantías constitucionales y legales.
                </li>
                <li>
                  Acceder en forma gratuita a sus datos personales que hayan
                  sido objeto de tratamiento.
                </li>
              </ul>
              <p className="PoliticsAccordion__item-law">
                <sup>2</sup> Art. 2, Ley 1581 de 2012.
                <br />
                <sup>3</sup> Art. 4, Ley 1581 de 2012.
                <br />
                <sup>4</sup> Art. 8, Ley 1581 de 2012.
              </p>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          square
          expanded={expanded === `panel3`}
          onChange={handleChange(`panel3`)}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <h3 className="PoliticsAccordion__title">
              4. LEGITIMACIÓN PARA EL EJERCICIO DE LOS DERECHOS DEL TITULAR
              <sup>5</sup>
            </h3>
          </AccordionSummary>
          <AccordionDetails>
            <div className="PoliticsAccordion__item-container">
              <p className="PoliticsAccordion__item-description">
                Estos derechos podrán ser ejercidos por:
              </p>
              <ol type="1">
                <li>
                  Por el Titular, quien deberá acreditar su identidad en forma
                  suficiente por los distintos medios que le ponga a disposición
                  el responsable.
                </li>
                <li>
                  Por sus causahabientes, quienes deberán acreditar tal calidad.
                </li>
                <li>
                  Por el representante y/o apoderado del Titular, previa
                  acreditación de la representación o apoderamiento.
                </li>
                <li>Por estipulación a favor de otro o para otro.</li>
              </ol>
              <p className="PoliticsAccordion__item-description">
                Los derechos de los niños, niñas o adolescentes se ejercerán por
                las personas que estén facultadas para representarlos.
              </p>
              <h4 className="PoliticsAccordion__item-subtitle">
                CASOS EN QUE NO ES NECESARIA LA AUTORIZACIÓN<sup>6</sup>
              </h4>
              <p className="PoliticsAccordion__item-description">
                No será necesaria la autorización del manejo de datos personales
                en los siguientes eventos:
              </p>
              <ol type="1">
                <li>
                  Información requerida por una entidad pública o administrativa
                  en ejercicio de sus funciones legales o por orden judicial.
                </li>
                <li>Datos de naturaleza pública.</li>
                <li>Casos de urgencia médica o sanitaria</li>
                <li>
                  Tratamiento de información autorizado por la ley para fines
                  históricos, estadísticos o científicos Datos relacionados con
                  el Registro Civil de las Personas.
                </li>
              </ol>
              <h4 className="PoliticsAccordion__item-subtitle">
                DEBERES DEL TITULAR DE LA INFORMACIÓN<sup>7</sup>
              </h4>
              <p className="PoliticsAccordion__item-description">
                De acuerdo con lo contemplado por la normatividad vigente
                aplicable en materia de protección de datos, los siguientes son
                los deberes del responsable del titular de los datos personales:
              </p>
              <ul>
                <li>
                  Garantizar al titular, en todo tiempo, el pleno y efectivo
                  ejercicio del derecho de hábeas data. Solicitar y conservar,
                  copia de la respectiva autorización otorgada por el titular
                  para el tratamiento de datos personales.
                </li>
                <li>
                  Informar debidamente al titular sobre la finalidad de la
                  recolección y los derechos que le asisten en virtud de la
                  autorización otorgada.
                </li>
                <li>
                  Conservar la información bajo las condiciones de seguridad
                  necesarias para impedir su adulteración, pérdida, consulta,
                  uso o acceso no autorizado o fraudulento.
                </li>
                <li>
                  Garantizar que la información sea veraz, completa, exacta,
                  actualizada, comprobable y comprensible.
                </li>
                <li>
                  Actualizar oportunamente la información, atendiendo de esta
                  forma todas las novedades respecto de los datos del titular.
                </li>
                <li>
                  Implementar todas las medidas necesarias para que la
                  información se mantenga actualizada.
                </li>
                <li>
                  Rectificar la información cuando sea incorrecta y comunicar lo
                  pertinente.
                </li>
                <li>
                  Respetar las condiciones de seguridad y privacidad de la
                  información del titular.
                </li>
                <li>
                  Tramitar las consultas y reclamos formulados en los términos
                  señalados por la ley.
                </li>
                <li>
                  Identificar cuando determinada información se encuentra en
                  discusión por parte del titular.
                </li>
              </ul>
              <p className="PoliticsAccordion__item-law">
                <sup>5</sup> Art. 20, Decreto 1377 de 2013, régimen general de
                protección de datos personales.
                <br />
                <sup>6</sup> Art. 10, Ley 1581 de 2012.
                <br />
                <sup>7</sup> Art. 17, Ley 1581 de 2012.
              </p>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          square
          expanded={expanded === `panel4`}
          onChange={handleChange(`panel4`)}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <h3 className="PoliticsAccordion__title">
              5. TRATAMIENTO AL CUAL SERÁN SOMETIDOS LOS DATOS Y FINALIDAD DEL
              MISMO
              <sup>8</sup>
            </h3>
          </AccordionSummary>
          <AccordionDetails>
            <div className="PoliticsAccordion__item-container">
              <p className="PoliticsAccordion__item-description">
                Se someterán al mismo tratamiento de los datos personales
                cualquier persona con la que <strong>PROYECTA-T LTDA</strong>,
                tuviera establecida o estableciera una relación permanente u
                ocasional dentro del marco legal y contractual que se rija en
                Colombia, por lo tanto los datos personales podrán ser
                recolectados y tratados para:
              </p>
              <ul>
                <li>
                  Con el fin de cumplir y desarrollar el objeto social de{' '}
                  <strong>PROYECTA-T LTDA</strong> conforme a sus estatutos.
                </li>
                <li>
                  Realizar el envío de información al mercado asegurador o
                  reasegurador relacionada con fines pertinentes teniendo en
                  cuenta el objeto social de la empresa.
                </li>
                <li>
                  Cumplir con la normatividad vigente en Colombia para las
                  sociedades corredoras de seguros vigentes.
                </li>
                <li>
                  Cumplir las normas aplicables a proveedores y contratistas,
                  incluyendo pero sin limitarse a las tributarias y comerciales
                </li>
                <li>
                  Cumplir lo dispuesto por el ordenamiento jurídico colombiano
                  en materia laboral y de seguridad social, entre otras,
                  aplicables trabajadores, contratistas y colaboradores, si
                  aplicare.
                </li>
                <li>
                  Realizar encuestas de seguimiento y satisfacción entre otras
                  relacionadas con los servicios que prestamos como empresa.
                </li>
                <li>
                  Fomentar la cultura del aseguramiento y la colocación de
                  seguros.
                </li>
                <li>Cumplir todos sus compromisos contractuales.</li>
                <li>
                  Cumplir órdenes e instrucciones que emitan las autoridades
                  competentes en materia de seguros y las que apliquen teniendo
                  en cuenta el objeto económico.
                </li>
              </ul>
              <h4 className="PoliticsAccordion__item-subtitle">
                AVISO DE PRIVACIDAD Y AUTORIZACIÓN<sup>9</sup>
              </h4>
              <p className="PoliticsAccordion__item-description">
                En cumplimiento de lo previsto en la Ley 1581 de 2012 “Por la
                cual se dictan disposiciones generales para la protección de
                datos personales” y el Decreto 1377 de 2013, ponemos a su
                disposición el presente Aviso de Privacidad el cual corresponde
                al documento físico, electrónico o en cualquier otro formato,
                puesto a disposición del titular responsable del tratamiento de
                los datos personales con el fin de informarle acerca del
                tratamiento de sus datos personales. Por lo tanto a través de
                este documento se solicita autorización y se comunica al titular
                la información relacionada con la existencia de las políticas de
                tratamiento de información de <strong>PROYECTA-T LTDA</strong> y
                que le serán aplicables, la forma de acceder a las mismas y las
                características del tratamiento que se pretende dar a los datos
                personales.
                <br />
                <br />
                Para el tratamiento de datos personales se requiere prueba de la
                autorización previa e informada del Titular. Se entenderá que la
                autorización cumple con los requisitos cuando se manifieste por
                escrito, de forma oral o mediante conductas inequívocas del
                Titular que permita concluir de esta forma razonable que otorgó
                la autorización. Los datos personales que se encuentren en
                fuentes de acceso público, con independencia del medio por el
                cual se tenga acceso, entendiéndose por tales aquellos datos o
                bases de datos que se encuentren a disposición del público,
                pueden ser tratados por cualquier persona siempre y cuando, por
                su naturaleza, sean datos públicos. Para solicitar la
                autorización del Titular, <strong>PROYECTA-T LTDA</strong> como
                responsable del tratamiento, adopta procedimientos para
                solicitar dicha autorización, durante el momento de la
                recolección de los datos, en caso de cambiar la finalidad del
                Tratamiento, solicitará una nueva autorización del Titular.
                <br />
                <br />
                Para efectos de dar cumplimiento a las obligaciones inherentes y
                necesarias a los servicios que ofrecemos, es necesario obtener
                mínimo los siguientes datos<sup>10</sup>:
              </p>
              <ul>
                <li>Nombres</li>
                <li>Apellidos</li>
                <li>Razón social</li>
                <li>Documento de identificación</li>
                <li>Dirección</li>
                <li>Correo electrónico</li>
                <li>Teléfonos de contactos</li>
              </ul>
              <p className="PoliticsAccordion__item-description">
                Una vez obtenida la autorización del titular para recolectar,
                almacenar, depurar, usar, analizar, circular, actualizar y
                cruzar con información propia o de terceros, a través de
                cualquier medio y en forma directa o a través de encargados del
                tratamiento para realizar actividades de mercadeo, promoción y/o
                publicidad propia o de terceros, administración de programas de
                seguros , solicitud de pólizas, manejo de siniestros, prestación
                de servicios, intermediación para temas de seguros y riesgos,
                cotizaciones, renovaciones, asesoría, ofertas, facturación,
                consultas, encuestas , seguimiento y control, gestión de
                cobranza, recaudo, mejoramiento del servicio, verificaciones,
                así como cualquier otra relacionada con nuestros servicios
                actuales y futuros, el Titular reconoce que sus datos personales
                serán recogidos y utilizados para las finalidades contenidas en
                el presente Manual.
              </p>
              <p className="PoliticsAccordion__item-law">
                <sup>8</sup> Art. 18, Ley 1581 de 2012.
                <br />
                <sup>9</sup> Art. 14, Decreto 1377 de 2013.
                <br />
                <sup>10</sup> Art. 15, Decreto 1377 de 2013.
              </p>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          square
          expanded={expanded === `panel5`}
          onChange={handleChange(`panel5`)}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <h3 className="PoliticsAccordion__title">
              6. GARANTÍAS DEL DERECHO DE ACCESO
            </h3>
          </AccordionSummary>
          <AccordionDetails>
            <div className="PoliticsAccordion__item-container">
              <p className="PoliticsAccordion__item-description">
                Con el fin de garantizar el derecho de acceso del titular de los
                datos, <strong>PROYECTA-T LTDA</strong> pondrá a disposición de
                éste, previa acreditación su identidad y legitimidad, o
                personalidad de su representante, sin costo o erogación alguna,
                de manera pormenorizada y detallada, los respectivos datos
                personales a través de todo tipo de medio, incluyendo los medios
                electrónicos que permitan el acceso directo del titular a ellos.
              </p>
              <h4 className="PoliticsAccordion__item-subtitle">
                PROCEDIMIENTO PARA QUE EL TITULAR EJERZA LOS DERECHOS A CONOCER,
                ACTUALIZAR, RECTIFICAR, SUPRIMIR INFORMACIÓN Y REVOCAR
                AUTORIZACIÓN.<sup>11</sup>
              </h4>
              <p className="PoliticsAccordion__item-description">
                La recolección, almacenamiento, uso y circulación de información
                de datos se harán de acuerdo a los procedimientos descritos en
                este documento, garantizando el cumplimiento de las políticas de
                seguridad de la información, por lo tanto antes de iniciar la
                prestación de un servicio se deberá solicitar la autorización de
                usos de datos personales al titular teniendo en cuenta que la
                autorización para el uso de datos puede ser tramitada a través
                de<sup>12</sup>:
              </p>
              <ul>
                <li>Formulario de conocimiento del cliente</li>
                <li>Mediante grabaciones telefónicas</li>
                <li>Mediante correo electrónico</li>
                <li>
                  Consultar las políticas y condiciones en la página{' '}
                  <Link to="/">www.proyectat.co.</Link>
                </li>
              </ul>
              <h4 className="PoliticsAccordion__item-subtitle">
                LAS CONSULTAS QUEJAS Y RECLAMOS FORMULADOS POR LOS TITULARES DE
                LOS DATOS PERSONALES BAJO TRATAMIENTO DE PROYECTA-T LTDA
              </h4>
              <p className="PoliticsAccordion__item-description">
                Independiente del mecanismo que sea usado por el usuario, la
                atención será atendida en un término máximo de diez (10) días
                hábiles contados a partir de la fecha de su recibo. En caso de
                no poder atender la consulta dentro de dicho término, se
                informará al interesado antes del vencimiento de los 15 días
                hábiles contados a partir del día siguiente a la fecha de su
                recibo, expresando los motivos de la demora y señalando la fecha
                en que se atenderá su consulta, la cual en ningún caso podrá
                superar los cinco (5) días hábiles siguientes al vencimiento del
                primer plazo. Las consultas podrán formularse al correo
                seri@proyectat.co
              </p>
              <h4 className="PoliticsAccordion__item-subtitle">
                SEGURIDAD DE LA INFORMACION Y MEDIDAS DE SEGURIDAD<sup>13</sup>
              </h4>
              <p className="PoliticsAccordion__item-description">
                En cumplimiento al principio de seguridad<sup>14</sup>{' '}
                establecido en la normatividad, <strong>PROYECTA-T</strong>{' '}
                adoptará las medidas que sean necesarias para otorgar seguridad
                a los registros evitando su adulteración, pérdida, consulta, uso
                o acceso no autorizado o fraudulento.
              </p>
              <h4 className="PoliticsAccordion__item-subtitle">
                RESPONSABLE Y ENCARGADO DEL TRATAMIENTO DE DATOS PERSONALES
              </h4>
              <p className="PoliticsAccordion__item-description">
                <strong>PROYECTA-T</strong> será la responsable del tratamiento
                de los datos personales relativos a sus clientes actuales o
                potenciales, trabajadores, extrabajadores, contratistas,
                proveedores y asesores y cualquier otro que tenga relación con
                la agencia.
              </p>
              <h4 className="PoliticsAccordion__item-subtitle">VIGENCIA</h4>
              <p className="PoliticsAccordion__item-description">
                El presente documento empieza a regir a partir del día 9 de
                agosto de 2018 y deja sin efectos los reglamentos o manuales
                especiales que se hubiesen podido adoptar anteriormente sobre la
                materia, con una vigencia indefinida salvo norma que lo
                modifica, adicione o aclare.
              </p>
              <p className="PoliticsAccordion__item-law">
                <sup>11</sup> Título V, Arts. 14, 15 y 16, Ley 1581 de 2012.
                <br />
                <sup>12</sup> Art. 17, Decreto 1377 de 2013.
                <br />
                <sup>13</sup> Art. 19, Decreto 1377 de 2013.
                <br />
                <sup>14</sup> Literal g, Art. 4, Ley 1581 de 2012, principios
                rectores, protección de datos.
              </p>
              <div className="PoliticsAccordion__item-address">
                <p>Carrera 43 B 7 SUR 177</p>
                <p>Medellín – Colombia</p>
                <p>Teléfono 3202115985</p>
                <p>www.proyectat.co</p>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default PoliticsAccordion;
