import React from 'react';
import Title from './title';
import '../scss/politics-manual.scss';
import { ManualItemInterface } from '../models/ManualItem';

export interface PoliticsManualProps {}

const ITEMS: ManualItemInterface[] = [
  {
    image: '/images/14.png',
    title: 'Nada de sorpresas',
    description:
      'La forma en cómo recopilamos y usamos tu información es exactamente como se describe aquí',
  },
  {
    image: '/images/8.png',
    title: 'Tu información a salvo',
    description:
      'Nuestro compromiso es la seguridad y confidencialidad de los datos que nos proporcionas',
  },
  {
    image: '/images/18.png',
    title: 'Tienes el control',
    description:
      'Nuestro compromiso es la seguridad y confidencialidad de los datos que nos proporcionas',
  },
];

const PoliticsManual: React.FC<PoliticsManualProps> = props => {
  return (
    <section className="PoliticsManual__container">
      <Title
        text="Nuestro manual para el tratamiento y protección de datos personales"
        className="PoliticsManual__title"
      />
      <div className="PoliticsManual__items">
        {ITEMS.map((ITEM, index) => (
          <div className="PoliticsManual__item" key={`manager-${index}`}>
            <div
              className="PoliticsManual__item-img"
              css={{
                backgroundImage: `url(${ITEM.image})`,
              }}
            ></div>
            <Title text={ITEM.title} className="PoliticsManual__item-title" />
            <p className="PoliticsManual__item-description">
              {ITEM.description}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default PoliticsManual;
